import { Device } from '../configuration/Configuration';

interface Sortable {
  sensor_id: string;
}

export function sortBySensorID<T extends Sortable>(list: T[]): T[] {
  return list.sort((a, b) => {
    return a.sensor_id === b.sensor_id ? 0 : a.sensor_id > b.sensor_id ? 1 : -1;
  });
}

export function sortSensorsByState(list: Device[]): Device[] {
  return list.sort((a, b) => {
    return a.status === b.status ? 0 : a.status > b.status ? 1 : -1;
  });
}