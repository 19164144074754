import React, { createRef } from 'react';

import './Forecast.scss';
import dayjs, { Dayjs } from 'dayjs';
import et from 'dayjs/locale/et'
import utc from 'dayjs/plugin/utc';
import { API } from '../App';
import { WeatherSymbol } from '@yr/weather-symbols';
import { ReactComponent as YourSvg } from '../../node_modules/@yr/weather-symbols/dist/graphicsDefs.svg';
import * as d3 from 'd3';

dayjs.locale(et);
dayjs.extend(utc);
d3.timeFormatDefaultLocale({
  dateTime: '%a %b %e %X %Y',
  date: '%Y.%m.%d',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
  shortDays: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
  months: ['Jaanuar', 'Veebruar', 'Märts', 'April', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
  shortMonths: ['Jan', 'Veb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
})
interface Props {}

interface ForecastSummary {
  date: string;
  t_min: number;
  t_max: number;
  w_min: number;
  w_max: number;
  symbol: number;
  symbol_var: string;
};

interface ForecastSummaryWithDate extends ForecastSummary {
  ddate: Dayjs;
}

interface ForecastSeries {
  date: string;
  symbol_var: string;
  temp: number;
};

interface ForecastSeriesWithDate extends ForecastSeries {
  ddate: Dayjs;
}

interface WeatherForecast {
  summary: ForecastSummary[],
  temp_series: ForecastSeries[],
};

export default class Forecast extends React.Component<any, {forecast?: WeatherForecast}> {

  intervalId;
  needsPrefix = [1, 2, 3, 40, 5, 41, 24, 6, 25, 42, 7, 43, 26, 20, 27, 44, 8, 45, 28, 21, 29];
  chartSVGRef: React.RefObject<SVGSVGElement>;

  constructor(props: Props) {
    super(props);
    this.chartSVGRef = createRef<SVGSVGElement>();
  }

  componentDidMount() {
    this.intervalId = setInterval(this.fetchWeather, 1000  * 60);
    this.fetchWeather();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  fetchWeather = () => {
    fetch(API + '/forecast')
    .then(response => response.json())
    .then(data => {
      this.setState({
        forecast: data
      }, () => {
        this.renderChart();
      });
    })
    .catch(err => {
      console.log(err);
    })
  }

  getSymbolId(symbol: number) {
    const prefix = this.needsPrefix.indexOf(symbol) >= 0 ? 'd': '';
    const suffix = symbol < 10 ? '0' : '';
    return 's' + suffix + symbol + prefix;
  }

  renderChart() {
    if (!this.chartSVGRef?.current) {
      return;
    }

    d3.select(this.chartSVGRef.current).selectAll('*').remove();
    const svg = d3.select(this.chartSVGRef.current);
    const margin = { left: 30, right: 30, top: 20, bottom: 20 };

    const width = +svg.attr('width');
    const height = +svg.attr('height');
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const isMobile = width <= 650;

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xAxisG = g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`);

    const yAxisG = g.append('g')
      .attr('class', 'y-axis');

    const yAxisRightG = g.append('g')
      .attr('class', 'y-axis-right')
      .attr('transform', `translate(${innerWidth + 5}, 0)`);

    const xScale = d3.scaleTime();
    const yScale = d3.scaleLinear();

    const xAxis = d3.axisBottom(xScale)
      .ticks(8)
      .tickSize(-innerHeight);

    const yTicks = 5;
    const yAxis = d3.axisLeft(yScale)
      .ticks(yTicks)
      .tickSize(-innerWidth);

    const yAxisRight = d3.axisRight(yScale)
      .ticks(yTicks)
      .tickSize(-innerWidth);

    const tzOffset = new Date().getTimezoneOffset();
    const xValue = (d: ForecastSeriesWithDate) => {
      return d.ddate.add(tzOffset, 'minute').toDate();
    };
    const xValueSummary = (d: ForecastSummaryWithDate) => {
      return d.ddate.set('hour', 12).add(tzOffset, 'minute').toDate();
    }
    const yValue = (d: ForecastSeriesWithDate) => d.temp / 100;
    const yValueMaxSummary = (d: ForecastSummary) => d.t_max / 100;
    const yValueMinSummary = (d: ForecastSummary) => d.t_min / 100;

    /*
    const line = d3.line<ForecastSeriesWithDate>()
      .x(d => xScale(xValue(d)))
      .y(d => yScale(yValue(d)))
      .curve(d3.curveMonotoneX);
    */

    const lineMax = d3.line<ForecastSummaryWithDate>()
      .x(d => xScale(xValueSummary(d)))
      .y(d => yScale(yValueMaxSummary(d)))
      .curve(d3.curveLinear);

    const lineMin = d3.line<ForecastSummaryWithDate>()
      .x(d => xScale(xValueSummary(d)))
      .y(d => yScale(yValueMinSummary(d)))
      .curve(d3.curveLinear);

    const startOfDay = dayjs.utc().set('hour', 0).set('minute', 0).set('second', 0);
    const in7Days = startOfDay.add(7, 'day').add(1, 'minute');
    const data: ForecastSeriesWithDate[] = this.state.forecast.temp_series.map(x => {
      return Object.assign(x, {ddate: dayjs.utc(x.date)})
    })
    .filter(x => x.ddate.isBefore(in7Days)); // TODO This takes some time, but why

    const xDomain = d3.extent(data, xValue);
    xDomain[0] = startOfDay.toDate();
    xScale
      .domain(xDomain)
      .range([0, innerWidth]);

    const yDomain = d3.extent(data, yValue);
    yScale
      .domain(yDomain)
      .range([innerHeight - 30, 0])
      .nice(yTicks);

    const summaryData: ForecastSummaryWithDate[] = this.state.forecast.summary.map(x => {
      return Object.assign(x, {ddate: dayjs.utc(x.date)});
    })
    .filter(x => x.ddate.isBefore(in7Days.subtract(1, 'day'))); // TODO This takes some time, but why;


    for (const day of summaryData) {
      const startX = Math.max(0, xScale(day.ddate.add(9, 'hours').add(tzOffset, 'minute').toDate()));
      const endX = xScale(day.ddate.add(24 - 9, 'hours').add(tzOffset, 'minute').toDate());
      const startY = yScale(day.t_min / 100);
      const endY = yScale(day.t_max /  100);

      g.append("rect")
        .attr("x", startX + (isMobile ? 0 : 5))
        .attr("y", endY)
        .attr("height", startY - endY)
        .attr("width", endX - startX - (isMobile ? 0 : 10))
        //.attr("stroke", "black")
        .style("fill", "url(#line-gradient)")
        // .style('fill', '#4d7549');
    }

    /*g.append('path')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 4)
      .attr('class', 'temp')
      .attr('d', line(data));*/

    /*g.append('path')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 4)
      .attr('class', 'temp')
      .attr('d', lineMax(summaryData));

    g.append('path')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 4)
      .attr('class', 'temp')
      .attr('d', lineMin(summaryData));
*/
    let lastTime: number = null;
    data.forEach((e, i) => {
      if (i + 1 === data.length) {
        return;
      }
      if (!e.symbol_var) {
        return;
      }
      const u = e.ddate.unix();
      if (null === lastTime) {
        lastTime = u;
      }
      else {
        const diff = u - lastTime;
        if (diff < 60 * 60 * 4) {
          return;
        }
      }
      lastTime = u;

      g.append('svg:image')
        .attr('x', xScale(xValue(e)) - 25 / 2)
        .attr('y', innerHeight - 10)
        .attr('class', 'symbol-img')
        .attr('width', 30)
        .attr('height', 30)
        .attr('transform', 'translate(0, -15)')
        .attr('xlink:href', '/yr/' + e.symbol_var + '.png');
    })

    xAxisG.call(xAxis);
    yAxisG.call(yAxis);
    yAxisRightG.call(yAxisRight);

    // TODO Move this to some more globally place
    const colorScaleDomain = [-30, 30];
    const colorScale = d3.scaleSequential(d3.interpolateRdBu)
      .domain(colorScaleDomain.slice().reverse() as any)

    svg.append('linearGradient')
      .attr('id', 'line-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr("x1", 0)
      .attr("y1", yScale(yDomain[0]))
      .attr("x2", 0)
      .attr("y2", yScale(yDomain[1]))
      .selectAll('stop')
      .data([
        {offset: '0%', color: colorScale(yDomain[0])},
        {offset: '100%', color: colorScale(yDomain[1])},
      ])
      .enter().append('stop')
        .attr('offset', function(d) { return d.offset; })
        .attr('stop-color', function(d) { return d.color; });

    const fontSize = isMobile ? 14 : 18;
    const boxSize = isMobile ? 30 : 40;
    summaryData.forEach(model => {
      const maxY = yScale(yValueMaxSummary(model)) - (isMobile ? 0 : 5)
      const minY = yScale(yValueMinSummary(model)) - (isMobile ? 0 : 5)
      let yAdjust = 0;
      const delta = minY - maxY;
      if (delta < 25) {
        yAdjust = 25 - (delta / 2)
      }
      g.append('rect')
        .attr('x', xScale(xValueSummary(model)) - boxSize / 2)
        .attr('y', maxY - yAdjust)
        .attr('width', boxSize)
        .attr('height', boxSize)
        .attr("rx", 6)
        .attr("ry", 6)
        .attr('transform', 'translate(0, -15)')
        .style('fill', 'white');

      g.append('rect')
        .attr('x', xScale(xValueSummary(model)) - boxSize / 2)
        .attr('y', minY + yAdjust)
        .attr('width', boxSize)
        .attr('height', boxSize)
        .attr("rx", 6)
        .attr("ry", 6)
        .attr('transform', 'translate(0, -15)')
        .style('fill', 'white');

      /*
      // Text color based on temp and inverted label if too similar
      let color = colorScale(model.t_max / 100);
      if (this.hexColorDelta(color, 'ffffff') > 0.8) {
        color = this.invertColor(color);
      }
      */
      g.append('text')
        .attr('x', xScale(xValueSummary(model)))
        .attr('y', yScale(yValueMaxSummary(model)) - yAdjust)
        .attr('width', 30)
        .attr('height', 30)
        .attr('font-weight', 'bold')
        .attr('font-size', fontSize + 'px')
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .style('fill', '#061822')
        .text(model.t_max / 100);

      g.append('text')
        .attr('x', xScale(xValueSummary(model)))
        .attr('y', yScale(yValueMinSummary(model)) + yAdjust)
        .attr('width', 30)
        .attr('height', 30)
        .attr('font-weight', 'bold')
        .attr('font-size', fontSize + 'px')
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .style('fill', '#061822')
        .text(model.t_min / 100)

    });
    /*
    Min/max lines on yAxis

    const maxYValue = yScale(yDomain[0]);
    const minYValue = yScale(yDomain[1]);

    yAxisG.append('line')
      .style("stroke", colorScale(yDomain[0]))
      .style("stroke-width", 5)
      .attr("x1", -7)
      .attr("y1", maxYValue)
      .attr("x2", 25)
      .attr("y2", maxYValue);

    yAxisG.append('line')
      .style("stroke", colorScale(yDomain[1]))
      .style("stroke-width", 3)
      .attr("x1", -7)
      .attr("y1", minYValue)
      .attr("x2", 25)
      .attr("y2", minYValue);

    */
  }

  hexColorDelta(rgbStr: string, hex2: string): number {
    const rgb = rgbStr.substring(4, rgbStr.length - 1).split(',').map(x => parseInt(x));
    // get red/green/blue int values of hex2
    var r2 = parseInt(hex2.substring(0, 2), 16);
    var g2 = parseInt(hex2.substring(2, 4), 16);
    var b2 = parseInt(hex2.substring(4, 6), 16);
    // calculate differences between reds, greens and blues
    var r = 255 - Math.abs(rgb[0] - r2);
    var g = 255 - Math.abs(rgb[1] - g2);
    var b = 255 - Math.abs(rgb[2] - b2);
    // limit differences between 0 and 1
    r /= 255;
    g /= 255;
    b /= 255;
    // 0 means opposit colors, 1 means same colors
    return (r + g + b) / 3;
  }

  invertColor(rgbStr: string) {
    const rgb = rgbStr.substring(4, rgbStr.length - 1).split(',').map(x => parseInt(x));
    // invert color components
    var r = (255 - rgb[0]).toString(16),
        g = (255 - rgb[1]).toString(16),
        b = (255 - rgb[2]).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  padZero(str: string): string {
    const len = 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  render() {
    if (!(this.state && this.state.forecast)) {
      return <span />
    }

    const isMobile = document.documentElement.clientWidth <= 650;
    const topMenuHeight = document.documentElement.clientHeight * 0.06
    const chartHeight = Math.round((document.documentElement.clientHeight * (isMobile ? 0.69 : 0.6)) - topMenuHeight);
    return <div className="prediction">
      <YourSvg />
      <div className="days">
        {
          Object.keys(this.state.forecast.summary).slice(0, 7).map((day, i) => {
            const p = this.state.forecast.summary[i];
            const d = dayjs(p.date);
            const dayName = d.format('dddd');
            return <div className={'day day-' + (i + 1)} key={day}>
              <span className="header-day">{dayName.substring(0, 1).toUpperCase() + dayName.substring(1)}</span>
              <span className="header-day-fl">{dayName.substring(0, 1).toUpperCase()}</span>
              <span className='header-date'>
                <span className='day-part'>{d.format('D')}</span>
                <span className='date-part'>{d.format('MMMM')}</span>
              </span>
              <span className="header-temp">
                <span>{p.t_max / 100}℃</span>
                <span>/</span>
                <span>{p.t_min / 100}℃</span>
              </span>
              <WeatherSymbol fallback={false} width={'90'} id={'s' + p.symbol_var} />
              <span className='wind'>
                <span className='w-min'>{p.w_min / 100}m/s</span>
                <span className='w-sep'> - </span>
                <span className='w-max'>{p.w_max / 100}m/s</span>
              </span>
            </div>
          })
        }
      </div>
      <div className="chart">
        <svg width={window.innerWidth} height={chartHeight} ref={this.chartSVGRef}></svg>
      </div>

    </div>
  }

}
