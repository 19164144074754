import React, { createRef } from 'react';
import * as d3 from 'd3';
import './Sensors.scss';
import { combineLatest, from, fromEvent, interval, Subscription } from 'rxjs';
import { Device } from '../configuration/Configuration';
import { debounceTime } from 'rxjs/operators';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import dayjs from 'dayjs'
import Forecast from '../forecast/Forecast';
import { API, sensorEventSubject } from '../App';
import Sky from '../sky/Sky';

import Modal from 'react-modal';
import SaunaModal, { getNotificationForSensor, postponeNotification } from './SaunaModal';
import ElectricityForecast from '../electricity/Electricity';
import deviceManager from '../deviceManager';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius          : '0',
    background            : '#000',
    border                : 'none',
    color                 : '#fff'
  },
  overlay: {
    background: 'rgba(0, 0, 0, .8)'
  }
};
Modal.setAppElement('#root')

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

enum Slide {
  temperature,
  minMax,
  deviceInfo
}
const slideOrder = [Slide.temperature, Slide.minMax, Slide.deviceInfo];
type Props = {};
type State = {
  refs: {sensorID: string, ref: React.RefObject<SVGSVGElement>}[];
  devices: Device[];
  legendRef: React.RefObject<SVGSVGElement>;
  modalIsOpen: boolean;
  modalDevice?: Device;
}

export type Sensor = 'SHT' | 'DS' | 'BMP' | 'VEML' | 'Tocon';

export type SensorEvent = {
  humidity: number;
  rssi: number;
  sensor_id: string;
  temperature: string;
  created: number;
  pressure: number;
  uv: number;
}

type MinMax = {
  sensor_id: string;
  min_temp: number;
  max_temp: number;
  max_temp_date: number;
  min_temp_date: number;
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize) {
    const temporal = [];
    for (let i = 0; i < this.length; i+= chunkSize){
        temporal.push(this.slice(i,i+chunkSize));
    }
    return temporal;
  }
});

declare global {
  interface Array<T> {
    chunk(size: number): Array<Array<T>>;
  }
}

export default class Sensors extends React.Component<Props, State> {

  chartDimensions = {
    borderRadius: 0
  };
  slideIdx = 0;
  // 1 minute update interval
  dataUpdateInterval = interval(1000 * 60);
  textGroups: [string, d3.Selection<SVGGElement, SensorEvent, SVGSVGElement | null, unknown>][] = [];
  animating = false;
  lastValuesOfSensors: {[key: string]: SensorEvent} = {};
  colorScaleDomain = [-35, 35];
  colorScale = d3.scaleSequential(d3.interpolateSpectral)
    .domain(this.colorScaleDomain.slice().reverse() as any)

  uvColorScale = d3.scaleQuantize<string>()
    .domain([1, 10])
    .range(['#BFE5A2', '#DDEFA1', '#FCF7A0', '#FEE6A1', '#FED5A2', '#FEC4A3', '#F9B4A7', '#F3A4AE', '#FFA3DC', '#E6BEFF']);

  subscriptions: Subscription[] = [];
  minmax: MinMax[] = [];
  reloadInterval: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      refs: [],
      legendRef: createRef<SVGSVGElement>(),
      devices: [],
      modalIsOpen: false
    }

    fromEvent(window, 'resize')
    .pipe(debounceTime(100))
    .subscribe(() => {
      this.renderGraph();
      this.renderLegend();
    });
  }

  componentDidMount() {
    this.getDataFromServer();
    this.subscriptions.push(sensorEventSubject.subscribe(e => {
      this.createRefs([e.sensor_id]);
      if (this.lastValuesOfSensors) {
        this.lastValuesOfSensors[e.sensor_id] = e;
        this.renderGraph(e.sensor_id);
      }
      const notification = getNotificationForSensor(e.sensor_id);
      if (undefined !== notification) {
        if (+e.temperature >= notification.temp && notification.shouldSound) {
          try {
            new Audio('/saun_valmis.mp3').play();
            this.switchToSlide(this.slideIdx);
          }
          catch (e) {
            console.error(e);
          }
          postponeNotification(e.sensor_id);
        }
      }
    }));

    this.renderLegend();
    this.subscriptions.push(this.dataUpdateInterval.subscribe(() => {
      fetch(API + '/minmax')
        .then(function(response) {
          return response.json();
        })
        .then(data => {
          this.minmax = data;
        })
    }));
    this.reloadInterval = setInterval(() => {
      this.getDataFromServer();
    }, 1000 * 60 * 60);
  }

  componentWillUnmount() {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
    clearTimeout(this.reloadInterval);
  }

  getDataFromServer() {
    this.setState({
      devices: deviceManager.getAllDevices()
    });
    this.subscriptions.push(combineLatest(
      from(fetch(API + '/latest')
      .then(function(response) {
        return response.json();
      })),
      from(fetch(API + '/minmax')
      .then(function(response) {
        return response.json();
      })), (latest, minmax) => ({latest, minmax})
    )
    .subscribe(results => {
      this.minmax = results.minmax;
      const data = results.latest as SensorEvent[];
      this.lastValuesOfSensors = data.reduce((acc, val) => {
        if (val.sensor_id in acc) {
          return acc;
        }
        acc[val.sensor_id] = val;
        return acc;
      }, {} as {[key: number]: SensorEvent});

      this.createRefs(Object.keys(this.lastValuesOfSensors as any));
      this.renderGraph();
    }));
  }


  createRefs = (sensorIDs: string[]): void => {
    const missingRefs: {sensorID: string; ref: React.RefObject<SVGSVGElement>}[] = [];
    for (let i = 0; i < sensorIDs.length; i++) {
      const sensorID = sensorIDs[i];
      const ref = this.state.refs.find(x => x.sensorID === sensorID);
      if (ref) {
        continue;
      }
      const newRef = createRef<SVGSVGElement>();
      missingRefs.push({sensorID, ref: newRef})
    }

    // console.log('missing ref len: ' + missingRefs.length)
    if (missingRefs.length > 0) {
      this.setState({refs: this.state.refs.concat(missingRefs)});
    }
  }

  switchToSlide = (idx: number) => {
    this.slideIdx = idx;
    this.textGroups.forEach(g => {
      const device = this.state.devices.find(x => x.sensor_id === g[0]);
      const textGroup = g[1];
      textGroup.selectAll('*').remove();
      this.fillCircle(device, textGroup);
    });
    this.setState({});
  }

  fillCircle = (device: Device, group: d3.Selection<SVGGElement, SensorEvent, SVGSVGElement | null, unknown>) => {
    if (undefined === device) {
      return;
    }
    const smallFont = this.chartDimensions.borderRadius * 1.5;
    const bigFont = smallFont * 1.8;
    const mediumFont = smallFont * 1.3;
    const slide = slideOrder[this.slideIdx];
    /* Create the text for each block */
    if (slide === Slide.temperature) {
      group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + bigFont + ')')
        .text((d: SensorEvent) => {
          const device = this.state.devices.find(x => x.sensor_id === d.sensor_id);
          if (device && device.name) {
            return device.name;
          }
          return 'Sensor: ' + d.sensor_id;
        });

      const humidityGroup = group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + -bigFont + ')')
        .attr('display', (d) => {
          if (d.humidity === undefined || d.humidity === null || d.humidity < 0) {
            return 'none';
          }
        })
        .text(d => {
          if (device.sensors?.indexOf('SHT') >= 0 || d.humidity > 0) {
            return d.humidity + '%'
          }
          return '';
        })

      if (device.sensors?.indexOf('BMP') >= 0) {
        // Move humidity to the left side
        humidityGroup.attr('text-anchor', 'end');
        humidityGroup.attr('transform', 'translate(' + -bigFont * 0.8 + ', ' + -bigFont + ')')

        // Pressure right
        group.append('text')
          .attr('fill', '#ccc')
          .attr('font-size', smallFont)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'central')
          .attr('transform', 'translate(0, ' + -bigFont + ')')
          .attr('display', (d) => {
            if (d.pressure === undefined || d.pressure === null || d.pressure < 0) {
              return 'none';
            }
          })
          .text(d => d.pressure + 'hPa')
      }
      if (device.sensors?.indexOf('Tocon') >= 0) {
        // Move humidity to the left side
        humidityGroup.attr('text-anchor', 'end');
        humidityGroup.attr('transform', 'translate(' + -bigFont * 0.8 + ', ' + -bigFont + ')')

        // Temperature right
        group.append('text')
          .attr('fill', '#ccc')
          .attr('font-size', smallFont)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'central')
          .attr('transform', 'translate(0, ' + -bigFont + ')')
          .text(d => (+d.temperature).toFixed(1) + '℃');

        group.append('text')
          .attr('fill', '#ccc')
          .attr('font-size', bigFont)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text((d) => {return d.uv + 'uv'});
      }
      else {
        group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', bigFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .text(function(d){return (+d.temperature).toFixed(1) + '℃'})
      }
      if (getNotificationForSensor(device.sensor_id) !== undefined) {
        group.append('text')
          .attr('fill', '#ccc')
          .attr('font-size', smallFont)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .attr('transform', 'translate(0, ' + (bigFont + bigFont) + ')')
          .text('🔔')
      }
    }
    else if (slide === Slide.minMax) {
      const maxTextGroup = group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + -mediumFont * 1.5 + ')');

      maxTextGroup.append('tspan')
        .attr('font-weight', 'bold')
        .text((d: SensorEvent) => {
          const entry = this.minmax.find(x => x.sensor_id === d.sensor_id);
          if (entry) {
            return `▲ ${entry.max_temp}℃`
          }
          return '';
        });

      maxTextGroup.append('tspan')
        .attr('dx', 30)
        .text((d: SensorEvent) => {
          const entry = this.minmax.find(x => x.sensor_id === d.sensor_id);
          if (entry) {
            const date = dayjs.unix(entry.max_temp_date).format('HH:mm');
            return `${date}`
          }
          return '';
        });

      const minTextGroup = group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + mediumFont * 1.5 + ')')

      minTextGroup.append('tspan')
        .attr('font-weight', 'bold')
        .text((d: SensorEvent) => {
          const entry = this.minmax.find(x => x.sensor_id === d.sensor_id);
          if (entry) {
            return `▼ ${entry.min_temp}℃`
          }
          return '';
        })
      minTextGroup.append('tspan')
        .attr('dx', 30)
        .text((d: SensorEvent) => {
          const entry = this.minmax.find(x => x.sensor_id === d.sensor_id);
          if (entry) {
            const date = dayjs.unix(entry.min_temp_date).format('HH:mm');
            return `${date}`
          }
          return '';
        })

      group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('transform', 'translate(0, 0)')
        .text((d: SensorEvent) => {
          const device = this.state.devices.find(x => x.sensor_id === d.sensor_id);
          if (device && device.name) {
            return device.name;
          }
          return 'Sensor: ' + d.sensor_id;
        });
    }
    else if (slide === Slide.deviceInfo) {
      group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + -bigFont + ')')
        .attr('display', (d) => {
          if (d.rssi === undefined || d.rssi === null) {
            return 'none';
          }
        })
        .text(d => d.rssi + 'dBm')
      group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0,0)')
        .text((d) => {
          return dayjs.unix(d.created).format('HH:mm');
        })
      group.append('text')
        .attr('fill', '#ccc')
        .attr('font-size', smallFont)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('transform', 'translate(0, ' + bigFont + ')')
        .text((d: SensorEvent) => {
          const device = this.state.devices.find(x => x.sensor_id === d.sensor_id);
          if (device && device.battery) {
            return device.battery + 'v';
          }
          return '';
        });
    }
  }

  renderGraph = (sensorId?: string) => {
    if (this.animating) {
      return;
    }
    const chartData = Object.keys(this.lastValuesOfSensors as any).map(sensor => {
      return {
        sensorId: sensor,
        lastValue: this.lastValuesOfSensors[sensor]
      }
    });

    const bwidth = document.body.clientWidth;
    const bheight = document.body.clientHeight;
    const chartWidth = Math.min(bwidth / 2.3, bheight / (Math.min(chartData.length, 4) / 1.8));
    const chartRadius = Math.min(180, chartWidth / 2.6);
    const borderRadius = chartRadius / 9;
    const chartHeight = chartRadius * 2 + borderRadius * 2 + 15;
    const now = new Date().getTime() / 1000;
    this.chartDimensions.borderRadius = borderRadius;

    for (let i = 0; i < chartData.length; i++) {
      const data = chartData[i];
      const device = this.state.devices.find(x => x.sensor_id === data.sensorId);

      if (sensorId && data.sensorId !== sensorId) {
        continue;
      }
      const ref = this.state.refs.find(x => x.sensorID === data.sensorId);
      if (!ref) {
        console.log('missing ref for device: ' + this.state.refs, data.sensorId);
        continue;
      }
      d3.select(ref.ref.current).selectAll('*').remove();
      const svg = d3.select(ref.ref.current)
        .attr('width', chartRadius * 2 + borderRadius * 2 + 10)
        .attr('height', chartHeight)

      const elem = svg.selectAll('g')
        .data([data.lastValue])

      var elemEnter = elem.enter()
        .append('g')
        .attr('transform', () => {
          return 'translate(' + (chartRadius + borderRadius + 7) + ',' + (chartRadius + borderRadius + 7) + ')';
        });

      elemEnter.append('circle')
        .attr('r', chartRadius)
        .attr('stroke', (d) => {
          if (now - d.created > 60 * 10) {
            return '#ccc';
          }
          if (device.sensors?.indexOf('Tocon') >= 0) {
            return this.uvColorScale(d.uv);
          }
          return this.colorScale(+d.temperature)
        })
        .attr('stroke-width', borderRadius)
        .attr('fill', 'rgba(0,0,0,0)')

      const scaleHum = d3.scaleLinear()
        .domain([0, 100])
        .range([0, 2 * Math.PI]);

      const doArc = (hum: number) => {
        if (hum < 0) {
          hum = 0;
        }
        const outerRadius = bwidth < 500 ? 5 : 10;
        return d3.arc()
          .innerRadius(chartRadius + borderRadius / 2)
          .outerRadius(chartRadius + borderRadius / 2 + outerRadius)
          .startAngle(0)
          .endAngle(scaleHum(hum));
      }

      elemEnter.append('path')
        .attr('d', (d) => {
          const t = doArc(d.humidity) as any;
          return t();
        })
        .style('fill', '#57bcff')

      const text = elemEnter.append('g');
      const idx = this.textGroups.findIndex(x => x[0] === data.sensorId);
      if (idx >= 0) {
        this.textGroups.splice(idx, 1);
      }
      this.textGroups.push([data.sensorId, text]);
      this.fillCircle(device, text);
    }
  }

  renderLegend() {
    const bwidth = document.body.clientWidth;
    const chartWidth = bwidth / 2.3;
    const chartRadius = Math.min(180, chartWidth / 2.6);
    const r = this.state.legendRef.current;
    d3.select(r).selectAll('*').remove();
    const svg = d3.select(r)
      .attr('width', chartRadius * 4)
      .attr('height', 30)


    //Extra scale since the color scale is interpolated
    const width = chartRadius * 4;
    var tempScale = d3.scaleLinear()
      .domain(this.colorScaleDomain)
      .range([0, width]);

    //Calculate the variables for the temp gradient
    var numStops = 10;
    let tempRange = tempScale.domain();
    tempRange[2] = tempRange[1] - tempRange[0];
    const tempPoint: number[] = [];

    for(var i = 0; i < numStops; i++) {
      tempPoint.push(i * tempRange[2]/(numStops-1) + tempRange[0]);
    }

    //Create the gradient
    svg.append('defs')
      .append('linearGradient')
      .attr('id', 'legend-weather')
      .attr('x1', '0%').attr('y1', '0%')
      .attr('x2', '100%').attr('y2', '0%')
      .selectAll('stop')
      .data(d3.range(numStops))
      .enter().append('stop')
      .attr('offset', (_d,i) => { return tempScale( tempPoint[i] ) / width; })
      .attr('stop-color', (_d,i) => { return this.colorScale( tempPoint[i] ); });

    //Color Legend container
    const legendsvg = svg.append('g')
      .attr('class', 'legendWrapper')
      .attr('transform', 'translate(0, 0)');

    //Draw the Rectangle
    legendsvg.append('rect')
      .attr('class', 'legendRect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', 8/2)
      .attr('width', width)
      .attr('height', 8)
      .style('fill', 'url(#legend-weather)');

    //Set scale for x-axis
    const xScale = d3.scaleLinear()
      .range([0, width])
      .domain(this.colorScaleDomain);

    //Define x-axis
    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickValues([-30, -20, -10, 0, 10, 20, 30])
      .tickFormat((d) => d + '°C')

    //Set up X axis
    legendsvg.append('g')
      .attr('class', 'temp-legend')
      .attr('transform', 'translate(0, 10)')
      .call(xAxis);
  }

  slideChange = (idx, _last, _meta) => {
    console.log('slide is changed to', idx);
  }

  openSaunaDialog = (device: Device) => {
    this.setState({modalIsOpen: true, modalDevice: device});
  };

  closeModal = () =>  {
    this.setState({modalIsOpen: false, modalDevice: undefined});
    this.switchToSlide(this.slideIdx);
  }

  temperatureRings = (): JSX.Element[] => {
    return this.state.refs.filter(x => {
      const device = this.state.devices.find(y => y.sensor_id === x.sensorID);
      return device?.status === 'active';
    }).chunk(4).map((chunk, i) => {
      return (
        <div key={'chunk-' + i} className="flex-row full-width">
          <div className="tabs auto-hide">
            <ShowChartIcon onClick={() => this.switchToSlide(0)} />
            <SwapVertIcon onClick={() => this.switchToSlide(1)}/>
            <PermDataSettingIcon onClick={() => this.switchToSlide(2)} />
          </div>
        <div className='sensors'>
          {chunk.map(x => {
            const device = this.state.devices.find(y => y.sensor_id === x.sensorID);
            if (device) {
              if (device.sensors?.indexOf('DS') >= 0) {
                let buttonPressTimer;
                const buttonPress = (e) => {
                  if (e.button && e.button === 2) {
                    // Right button press
                    return;
                  }
                  buttonPressTimer = setTimeout(() => this.openSaunaDialog(device), 500);
                }
                const buttonRelease = () => {
                  clearTimeout(buttonPressTimer);
                }

                return (<svg onTouchStart={buttonPress}
                            // onTouchMove={buttonRelease}
                            onTouchEnd={buttonRelease}
                            onMouseDown={buttonPress}
                            onMouseUp={buttonRelease}
                            onMouseLeave={buttonRelease}
                            onDoubleClick={() => this.openSaunaDialog(device)} key={x.sensorID} ref={x.ref}></svg>);
              }
              else {
                return <svg key={x.sensorID} ref={x.ref}></svg>
              }
            }
            return null;
          })}
        </div></div>);
    })
  }

  render() {
    let modalContainer: JSX.Element | null = null;
    if (this.state.modalIsOpen && this.state.modalDevice) {
      const temp  = this.lastValuesOfSensors[this.state.modalDevice.sensor_id].temperature;
      modalContainer = (<Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <SaunaModal current_temp={+temp} sensor_id={this.state.modalDevice.sensor_id} />
        </Modal>);
    }

    const slides = [
      ...this.temperatureRings(),
      <ElectricityForecast key="elekter" />,
      <Sky key="sky" />,
      <Forecast key={'forecast'} />
    ];
    return (
      <div>
        {modalContainer}
        <div className="flex-row full-height">
          <AutoPlaySwipeableViews slideClassName="slide-container" onChangeIndex={this.slideChange} disableLazyLoading={true} interval={30000} enableMouseEvents={true}>
            {
              slides
            }
          </AutoPlaySwipeableViews>
        </div>
        {
          /*
            <div className="legend auto-hide">
              <svg ref={this.state.legendRef}></svg>
            </div>
          */
        }
      </div>
    );
  }
}
