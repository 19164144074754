import './Electricity.scss';
import React, { createRef } from 'react';
import { API } from '../App';
import * as d3 from 'd3';
import dayjs, { Dayjs } from 'dayjs';

interface Electricity {
  price: number;
  timestamp: number;
}

interface ElectricityWithDate extends Electricity {
  ddate: Dayjs;
}

interface Props { }

export default class ElectricityForecast extends React.Component<any, {electricity?: Electricity[]}> {

  intervalId;
  needsPrefix = [1, 2, 3, 40, 5, 41, 24, 6, 25, 42, 7, 43, 26, 20, 27, 44, 8, 45, 28, 21, 29];
  chartSVGRef: React.RefObject<SVGSVGElement>;

  constructor(props: Props) {
    super(props);
    this.chartSVGRef = createRef<SVGSVGElement>();
  }

  fetchElectricity = () => {
    fetch(API + '/electricity')
    .then(response => response.json())
    .then(data => {
      this.setState({
        electricity: data.map(x => {
          let withTax = x.price + x.price * .2;
          withTax = Math.round(withTax * 100) / 100;
          return Object.assign(x, {price: withTax})
        })
      }, () => {
        this.renderChart();
      });
    })
    .catch(err => {
      console.log(err);
    })
  }

  componentDidMount() {
    this.intervalId = setInterval(this.fetchElectricity, 1000  * 60);
    this.fetchElectricity();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  renderChart() {
    if (!this.chartSVGRef?.current) {
      return;
    }

    d3.select(this.chartSVGRef.current).selectAll('*').remove();
    const svg = d3.select(this.chartSVGRef.current);
    const margin = { left: 35, right: 60, top: 40, bottom: 20 };

    const width = +svg.attr('width');
    const height = +svg.attr('height');
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xAxisG = g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`);

    const yAxisG = g.append('g')
      .attr('class', 'y-axis');

    const xScale = d3.scaleTime();
    const yScale = d3.scaleLinear();
    const yTicks = 5;

    const data: ElectricityWithDate[] = this.state.electricity.map(x => {
      return Object.assign(x, {ddate: dayjs.unix(x.timestamp)})
    });

    let tzOffset = new Date().getTimezoneOffset();
    tzOffset = 0;

    const xValue = (d: ElectricityWithDate) => {
      return d.ddate.subtract(tzOffset, 'minute').toDate();
    };

    const xDomain = d3.extent(data, xValue);
    xScale
      .domain(xDomain)
      .range([0, innerWidth]);

    const yDomain = d3.extent(data, d => d.price);
    yScale
      .domain(yDomain)
      .range([innerHeight - 30, 0])
      .nice(yTicks);

    const xAxis = d3.axisBottom(xScale)
      .ticks(16)
      .tickSize(-innerHeight)
      .tickFormat(d3.timeFormat('%H'));

    const yAxis = d3.axisLeft(yScale)
      .ticks(yTicks)
      .tickSize(-innerWidth);

    const area = d3.area<ElectricityWithDate>()
      .x(d => xScale(xValue(d)))
      .y0(innerHeight)
      .y1(d => yScale(d.price))
      .curve(d3.curveStepAfter);


    g.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#888")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line<ElectricityWithDate>()
        .x((d) => xScale(xValue(d)))
        .y((d) => yScale(d.price))
        .curve(d3.curveStepAfter)
        )

    g.append('path')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 4)
      .attr('class', 'temp area')
      .attr('d', area(data));

    svg.append("linearGradient")
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0).attr("y1", yScale(202.7 * 2))
      .attr("x2", 0).attr("y2", yScale(0))
    .selectAll("stop")
      .data([
        {offset: "0%", color: "rgba(255,0,0,.2)"},
        {offset: "30%", color: "rgba(255,0,0,.2)"},
        {offset: "45%", color: "rgba(178, 95, 74,.2)"},
        {offset: "55%", color: "rgba(178, 95, 74,.2)"},
        {offset: "60%", color: "rgba(0,255,0,.2)"},
        {offset: "100%", color: "rgba(0,255,0,.2)"}
      ])
    .enter().append("stop")
      .attr("offset", function(d) { return d.offset; })
      .attr("stop-color", function(d) { return d.color; });

    const today = new Date();
    const ts = dayjs().minute(0).second(0).millisecond(0).unix();
    const currentEntryIdx = this.state.electricity.findIndex(x => x.timestamp >= ts);
    const curEntry = this.state.electricity[currentEntryIdx];
    const nextEntry = this.state.electricity[Math.max(0, currentEntryIdx + 1)];
    const priceRange = nextEntry.price - curEntry.price;
    const tsEnd = dayjs().hour(23).minute(59).second(0).millisecond(0).unix();
    const tsStart = dayjs().hour(0).minute(0).second(0).millisecond(0).unix();
    const maxEntry = this.state.electricity.filter(x => x.timestamp <= tsEnd && x.timestamp >= tsStart).sort((a, b) => a.price > b.price ? -1 : (a.price === b.price ? 0 : 1))[0];
    const curMinute = today.getMinutes() / 60;

    g.append("line")
      .attr("class", "line")
      .attr("x1", xScale(today))
      .attr("y1", yScale((curMinute * priceRange) + curEntry.price) - 100)
      .attr("x2", xScale(today))
      .attr("y2", yScale((curMinute * priceRange) + curEntry.price) + 100)
      .style("stroke-width", 2)
      .style("stroke", "white")
      .style("fill", "none");

    let lastI = -1;
    data.forEach((model, i) => {
      if (i > 0 && i < data.length - 1 && !((i - lastI) > 2)) {
        // Bigger than previous and bigger than next
        const prev = data[i - 1].price;
        const next = data[i + 1].price;
        const now = model.price;
        if (!(now >= prev && now >= next) && !(now < prev && now < next)) {
          return;
        }
      }

      const priceY = yScale(model.price);
      const priceX = xScale(model.ddate.subtract(tzOffset, 'minute').add(30, 'minute').toDate());
      const maxValue = model.price === maxEntry.price;
      g.append('circle')
        .attr('cx', priceX)
        .attr('cy', priceY)
        .attr('r', 5)
        .style('fill', maxValue ? '#f55' : '#fff');

      // TODO do bounding client rect
      g.append('text')
        .attr('x', priceX)
        .attr('y', priceY)
        .attr('width', 40)
        .attr('height', 30)
        .attr('font-weight', 'bold')
        .attr("text-anchor", "middle")
        .attr('font-size', maxValue ? '16px' : '14px')
        .attr('fill', maxValue ? '#f55' : '#fff')
        .attr('transform', 'translate(0, ' + -20 * (i % 2 === 0 ? -1 : 1) + ')')
        .attr("dominant-baseline", "central")
        .text(Math.round(model.price));
      lastI = i;
    });

    xAxisG.call(xAxis);
    yAxisG.call(yAxis);
  }

  render() {
    if (!(this.state && this.state.electricity)) {
      return <span />
    }

    const ts = dayjs().minute(0).second(0).millisecond(0).unix();
    const tsEnd = dayjs().hour(23).minute(59).second(0).millisecond(0).unix();
    const tsStart = dayjs().hour(0).minute(0).second(0).millisecond(0).unix();
    const currentEntry = this.state.electricity.find(x => x.timestamp >= ts);
    const maxEntry = this.state.electricity.filter(x => x.timestamp <= tsEnd && x.timestamp >= tsStart).sort((a, b) => a.price > b.price ? -1 : (a.price === b.price ? 0 : 1))[0];
    const chartHeight = Math.round(document.documentElement.clientHeight * 0.7);
    const chartWidth = Math.round(document.documentElement.clientWidth * 0.95);
    if (!maxEntry || !currentEntry) {
      return <div className="electricity missing"><h2>Ei leidnud elektri hinda</h2></div>
    }
    return (
      <div className="electricity">
        <div className="chart">
          <div className="stat">
            <b>Hind praegu: <span className={'current' + (maxEntry.price === currentEntry.price ? ' max' : ' not-max')}>{currentEntry.price.toFixed(2)}€</span></b>
            <b>Tänane maksimum kell: <span className="current">{dayjs.unix(maxEntry.timestamp).format('HH:mm')}</span> hinnaga <span className='current max'>{maxEntry.price.toFixed(2)}€</span></b>
          </div>
          <svg width={chartWidth} height={chartHeight} ref={this.chartSVGRef}></svg>
        </div>
      </div>
    );
  }
}
