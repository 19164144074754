import React, { createRef } from 'react';
import './History.scss';
import { Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import { Device } from '../configuration/Configuration';
import RouterIcon from '@material-ui/icons/Router';
import * as d3 from 'd3';
import { API } from '../App';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import deviceManager from '../deviceManager';
import { sortBySensorID } from './helper';

export type SensorHistoryEvent = {
  humidity: number;
  rssi: number;
  sensor_id: number;
  temperature: number;
  date: Date;
  battery: number;
  uv: number;
  agg_created: string;
}

enum ChartDuration {
  day,
  week,
  month,
  year
}

export enum ChartType {
  temp,
  humidity,
  battery,
  rssi,
  uv,
}

export const TypeColor = {}
TypeColor[ChartType.temp] = '238, 46, 49';
TypeColor[ChartType.humidity] = '147, 183, 190';
TypeColor[ChartType.battery] = '244, 192, 149';
TypeColor[ChartType.rssi] = '82, 25, 69';
TypeColor[ChartType.uv] = '182, 55, 19';

type Props = {};
type State = {
  devices: Device[];
  selectedDevice?: string;
  chartRef: React.RefObject<SVGSVGElement>;
  chartDuration: ChartDuration;
  chartType: ChartType[];
}

export default class History extends React.Component<Props, State> {
  private unsubscribe = new Subject();
  private lastFetchedData: SensorHistoryEvent[] = [];
  // Sensor ID, start_time, end_time
  private loadMoreData = new Subject<[string, number, number]>();

  constructor(props: Props) {
    super(props);

    this.state = {
      devices: [],
      selectedDevice: undefined,
      chartRef: createRef<SVGSVGElement>(),
      chartDuration: ChartDuration.week,
      chartType: [ChartType.temp, ChartType.humidity]
    }
  }

  componentDidMount() {
    const devices = deviceManager.getAllActiveDevices();
    sortBySensorID(devices)
    this.setState({devices})
    if (devices.length > 0) {
      this.selectDevice(devices[0]);
    }

    this.loadMoreData.pipe(takeUntil(this.unsubscribe), debounceTime(1000)).subscribe(job => {
      const resolution = Math.round((job[2] - job[1]) / 1000 / 60 / 330);
      const params = new URLSearchParams({'sensor_id': job[0].toString(), 'start_time': Math.round(job[1] / 1000).toString(), 'end_time': Math.round(job[2] / 1000).toString(), 'resolution': resolution.toString()}) as any;

      this.makeFetchRequest(params, (res) => {
        const parsedData = res.map(e => {
          if (isNaN(e.agg_created as any)) {
            e.date = new Date(e.agg_created);
          }
          else {
            e.date = new Date(e.agg_created as any * 1000);
          }
          return e;
        })
        this.lastFetchedData = parsedData;
        this.renderChart(parsedData);
      })
    });
  }

  componentWillUnmount() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  selectDevice = (device: Device) => {
    this.setState({
      selectedDevice: device.sensor_id
    }, () => {
      this.fetchHistory(device);
    });
  }

  makeFetchRequest(params: string, cb: (res: SensorHistoryEvent[]) => void) {
    const url = new URL(window.location.protocol + API + '/history');
    url.search = params;
    fetch(url as any)
      .then(response => response.json())
      .then((res: SensorHistoryEvent[]) => {
        cb(res);
      })
  }

  fetchHistory = (device: Device) => {
    const now = +new Date() / 1000;
    let startTime = 0;
    let resolution = 30;
    if (this.state.chartDuration === ChartDuration.day) {
      startTime = now - 60 * 60 * 24 * 1;
      resolution = 10;
    }
    else if (this.state.chartDuration === ChartDuration.week) {
      startTime = now - 60 * 60 * 24 * 7;
      resolution = 30;
    }
    else if (this.state.chartDuration === ChartDuration.month) {
      startTime = now - 60 * 60 * 24 * 30;
      resolution = 120;
    }
    else if (this.state.chartDuration === ChartDuration.year) {
      startTime = now - 60 * 60 * 24 * 365;
      resolution = 120 * 2;
    }

    const params = new URLSearchParams({'sensor_id': device.sensor_id as any, 'start_time': Math.round(startTime) as any, 'resolution': resolution as any}) as any;
    this.makeFetchRequest(params, (res: SensorHistoryEvent[]) => {
      const parsedData = res.map(e => {
        if (isNaN(e.agg_created as any)) {
          e.date = new Date(e.agg_created);
        }
        else {
          e.date = new Date(e.agg_created as any * 1000);
        }
        return e;
      })
      this.lastFetchedData = parsedData;
      this.renderChart(parsedData);
    })
  }

  renderChart = (history: SensorHistoryEvent[]) => {
    const el = document.querySelector('.history-root');
    if (!el) {
      return;
    }
    const data = history.sort((a, b) => {
      return a.date === b.date ? 0 : a.date > b.date ? 1 : -1;
    });

    const containerWidth = el.getBoundingClientRect().width;
    const screenHeight = window.screen.height * .5;

    d3.select(this.state.chartRef.current).selectAll('*').remove();
    const svg = d3.select(this.state.chartRef.current)
      .attr("width", containerWidth)
      .attr("height", screenHeight)
    .append("g")
    .attr("transform",
      "translate(" + 50 + "," + 10 + ")");;

    const originalX = d3
      .scaleTime()
      .range([0, containerWidth - 100])
      .domain(d3.extent(data, (d) => d.date) as [Date, Date]);

    let x = originalX;

    const formatMillisecond = d3.timeFormat('.%L'),
      formatSecond = d3.timeFormat(':%S'),
      formatMinute = d3.timeFormat('%H:%M'),
      formatHour = d3.timeFormat('%H:%M'),
      formatDay = d3.timeFormat('%a %d'),
      formatWeek = d3.timeFormat('%b %d'),
      formatMonth = d3.timeFormat('%B'),
      formatYear = d3.timeFormat('%Y');

    // Define filter conditions
    const multiFormat = (date) => {
      return (d3.timeSecond(date) < date ? formatMillisecond
        : d3.timeMinute(date) < date ? formatSecond
        : d3.timeHour(date) < date ? formatMinute
        : d3.timeDay(date) < date ? formatHour
        : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
        : d3.timeYear(date) < date ? formatMonth
        : formatYear)(date);
    }
    svg.append("g")
      .attr('class', 'axis-bottom')
      .attr("transform", "translate(0," + (screenHeight - 50) + ")")
      .call(d3.axisBottom(x).tickFormat(multiFormat).ticks(containerWidth < 700 ? 5 : 10));

    const originalYAxes = this.state.chartType.map(x => {
      switch (x) {
        case ChartType.temp:
          return d3
            .scaleLinear()
            .domain([Math.floor(d3.min(data, d => d.temperature)), Math.ceil(d3.max(data, d => d.temperature) as number)])
            .range([screenHeight - 50, 0])
        case ChartType.humidity:
          return d3
            .scaleLinear()
            .domain([0, 100])
            .range([screenHeight - 50, 0]);
        case ChartType.battery:
          return d3
            .scaleLinear()
            .domain([3, 4.3])
            .range([screenHeight - 50, 0])
        case ChartType.rssi:
          return d3
            .scaleLinear()
            .domain([0, -100])
            .range([screenHeight - 50, 0]);
        case ChartType.uv:
          return d3
            .scaleLinear()
            .domain([0, 10])
            .range([screenHeight - 50, 0]);
        default:
          throw new Error('Invalid ChartType')
      }
    });
    let yAxes = Object.assign([], originalYAxes);

    // gridlines in x axis function
    function make_x_gridlines() {
      return d3.axisBottom(x)
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(yAxes[0])
    }

    // add the X gridlines
    svg.append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + (screenHeight - 50) + ")")
      .call(make_x_gridlines()
          .tickSize(-(screenHeight - 50))
          .tickFormat(() => {return ''})
      )

    // add the Y gridlines
    svg.append("g")
      .attr("class", "grid")
      .attr("transform", "translate(" + (0) + ",0)")
      .call(make_y_gridlines()
          .tickSize(-(containerWidth - 100))
          .tickFormat(() => {return ''})
      )

    svg.append("g")
      .attr('class', 'axis-left')
      .call(d3.axisLeft(yAxes[0]));

    if (yAxes.length > 1) {
      svg.append("g")
        .attr('class', 'axis-right')
        .attr("transform", "translate(" + (containerWidth - 100) + ",0)")
        .call(d3.axisRight(yAxes[1]).ticks(10).tickSize(10));
    }

    const chunk = (array, size) => {
      if (!array.length) {
        return [];
      }
      const head = array.slice(0, size);
      const tail = array.slice(size);
      return [head, ...chunk(tail, size)];
    };

    let _cachedMinMaxAvg: [Date, number, number, number, number, number][];
    const getMinMaxAverages = () => {
      if (undefined !== _cachedMinMaxAvg) {
        return _cachedMinMaxAvg;
      }
      let aggerated = data.reduce((acc, value) => {
        const key = value.date.toISOString().split('T')[0]
        if (!(key in acc)) {
          acc[key] = [[], []];
        }
        acc[key][0].push(value.temperature);
        acc[key][1].push(value.humidity);
        return acc;
      }, {}) as {[key: string]: [number[], number[]]};

      // Need to do more aggregation when on yearly
      if (this.state.chartDuration === ChartDuration.year) {
        const chunkedKeys = chunk(Object.keys(aggerated), 2) as [string[]];
        chunkedKeys.forEach(c => {
          const firstDate = c[0];
          for (let i = 1; i < c.length; i++) {
            const entry = aggerated[c[i]];
            aggerated[firstDate][0].push(...entry[0]);
            aggerated[firstDate][1].push(...entry[1]);
            delete aggerated[c[i]];
          }
        });
      }

      const minMaxAverage = Object.keys(aggerated).map(key => {
        const [temp, humidity] = aggerated[key];
        const minTemp = Math.min(...temp);
        const maxTemp = Math.max(...temp);
        const averageTemp = temp.reduce((a, v) => a + v, 0 ) / temp.length;

        const minHum = Math.min(...humidity);
        const maxHum = Math.max(...humidity);

        return [new Date(key), minTemp, maxTemp, averageTemp, minHum, maxHum];
      }) as [Date, number, number, number, number, number][];

      // Add current value to current datetime so graph wont cut off at midnight
      const latest = data[data.length - 1];
      if (undefined !== latest) {
        minMaxAverage.push([new Date(), latest.temperature, latest.temperature, latest.temperature, latest.humidity, latest.humidity]);
        minMaxAverage.shift();
        minMaxAverage[0][0] = data[0].date;
      }
      _cachedMinMaxAvg = minMaxAverage;
      return minMaxAverage;
    }

    this.state.chartType.forEach((type, axis) => {
      if (type === ChartType.temp) {
        if (false && (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year)) {
          const tempArea = d3.area<[Date, number, number, number, number, number]>()
            .x((d,_i) => x(d[0]))
            .y0((d) => yAxes[axis](d[1]))
            .y1((d) => yAxes[axis](d[2]))
            .curve(d3.curveNatural);

          svg.append('path')
            .attr('class', 'chart-temperature')
            .attr('d', tempArea(getMinMaxAverages()))
            .attr('fill', 'rgba(238, 46, 49, .7)');
        }
        else {
          svg.append("path")
            .datum(data)
            .attr('class', 'chart-temperature')
            .attr("fill", "none")
            .attr("stroke", "rgb(238, 46, 49)")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line<SensorHistoryEvent>()
              .x(function(d) { return x(d.date) })
              .y(function(d) { return yAxes[axis](d.temperature) })
            )
        }
      }
      if (type === ChartType.humidity) {
        if (false && (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year)) {
          const humArea = d3.area<[Date, number, number, number, number, number]>()
            .x((d,_i) => x(d[0]))
            .y0((d) => yAxes[axis](d[4]))
            .y1((d) => yAxes[axis](d[5]))
            .curve(d3.curveNatural);

          svg.append('path')
            .attr('class', "chart-humidity")
            .attr('d', humArea(getMinMaxAverages()))
            .attr('fill', 'rgba(147, 183, 190, .7)');
        }
        else {
          svg.append("path")
            .datum(data)
            .attr('class', 'chart-humidity')
            .attr("fill", "none")
            .attr("stroke", "rgb(147, 183, 190)")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line<SensorHistoryEvent>()
              .x(function(d) { return x(d.date) })
              .y(function(d) { return yAxes[axis](d.humidity) })
            )
        }
      }
      if (type === ChartType.battery) {
        svg.append("path")
          .datum(data)
          .attr('class', 'chart-battery')
          .attr("fill", "none")
          .attr("stroke", "rgb(244, 192, 149)")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line<SensorHistoryEvent>()
            .x(function(d) { return x(d.date) })
            .y(function(d) { return yAxes[axis](d.battery) })
          )
      }
      if (type === ChartType.rssi) {
        svg.append("path")
          .datum(data)
          .attr('class', 'chart-rssi')
          .attr("fill", "none")
          .attr("stroke", "rgb(82, 25, 69)")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line<SensorHistoryEvent>()
            .x(function(d) { return x(d.date) })
            .y(function(d) { return yAxes[axis](d.rssi) })
          )
      }
      if (type === ChartType.uv) {
        svg.append("path")
          .datum(data)
          .attr('class', 'chart-uv')
          .attr("fill", "none")
          .attr("stroke", "rgb(182, 55, 19)")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line<SensorHistoryEvent>()
            .x(function(d) { return x(d.date) })
            .y(function(d) { return yAxes[axis](d.uv) })
          )
      }
    });

    var focus = svg.append("g")
      .attr("class", "focus")
      .style("display", "none");

    focus.append('line')
      .style('stroke', '#aaa')
      .style('stroke-dasharray', ('3, 3'))
      .attr("x1", 0)
      .attr("y1", -1000)
      .attr("x2", 0)
      .attr("y2", 1000);

    const rect = focus.append('g');
    const dots = [1, 2].map(() => {
      return focus.append('circle')
      .attr('r', 5)
      .attr('fill', '#000');
    });

    rect.append("rect")
      .attr("class", "tooltip")
      .attr("width", 160)
      .attr("height", 80)
      .attr("x", 10)
      .attr("y", 0)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr('stroke', '#2378ae');

    rect.append("text")
      .attr("class", "tooltip-date")
      .attr("x", 18)
      .attr("y", 22);

    rect.append("rect")
      .attr("x", 18)
      .attr("y", 50 - 13)
      .attr('fill', `rgb(${TypeColor[this.state.chartType[0]]})`)
      .attr('width', 16)
      .attr('height', 16);

    rect.append("text")
      .attr("class", "tooltip-row-1")
      .attr("x", 18 + 16 + 10)
      .attr("y", 50);

    if (this.state.chartType.length > 1) {
      rect.append("rect")
        .attr("x", 18)
        .attr("y", 70 - 13)
        .attr('fill', `rgb(${TypeColor[this.state.chartType[1]]})`)
        .attr('width', 16)
        .attr('height', 16);

      rect.append("text")
        .attr("class", "tooltip-row-2")
        .attr("x", 18 + 16 + 10)
        .attr("y", 70);
    }

    const bisect = d3.bisector<SensorHistoryEvent, number>(d => d.date.getTime());
    const bisectAlt = d3.bisector<[Date, number, number, number, number, number], number>(d => d[0].getTime());

    const timeFormatter = d3.timeFormat('%d %B %H:%M');
    const timeFormatterAlt = d3.timeFormat('%d %B');

    const mousemove = (_e: any, _e2: any, e3: any) => {
      const x0 = x.invert(d3.mouse(e3[0])[0])
      const yy = yAxes.map(y => y.invert(d3.mouse(e3[0])[1]))

      let dataIdx = bisect.left(data, x0.getTime());
      if (dataIdx < 0 || undefined === data[dataIdx]) {
        return;
      }

      focus.attr("transform", "translate(" + x(x0) + "," + yAxes[0](yy[0]) + ")");
      if (x(x0) + 160 > containerWidth - 100) {
        rect.attr("transform", "translate(" + -((x(x0) + 160) - (containerWidth - 100)) + ",0)")
      }
      else {
        rect.attr("transform", "translate(0,0)")
      }

      focus.select(".tooltip-date").text(timeFormatter(x0));

      this.state.chartType.forEach((type, axis) => {
        if (type === ChartType.temp) {
          if (false && (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year)) {
            const minMaxAverages = getMinMaxAverages();
            dataIdx = bisectAlt.left(minMaxAverages, x0.getTime());
            if (dataIdx < 0 || undefined === minMaxAverages[dataIdx]) {
              return;
            }
            focus.select(".tooltip-row-" + (axis + 1)).text(minMaxAverages[dataIdx][1] + ' - ' + minMaxAverages[dataIdx][2] + ' ℃');
            focus.select(".tooltip-date").text(timeFormatterAlt(minMaxAverages[dataIdx][0]));

            dots[axis]
              .attr('cx', x(x0.getTime()) - x(x0))
              .attr('cy', yAxes[axis](minMaxAverages[dataIdx][2]) - yAxes[axis](yy[axis]));
          }
          else {
            focus.select(".tooltip-row-" + (axis + 1)).text(data[dataIdx].temperature + ' ℃');
            dots[axis]
              .attr('cx', x(x0.getTime()) - x(x0))
              .attr('cy', yAxes[axis](data[dataIdx].temperature) - yAxes[axis](yy[axis]));
          }
        }
        if (type === ChartType.humidity) {
          if (false && (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year)) {
            const minMaxAverages = getMinMaxAverages();
            dataIdx = bisectAlt.left(minMaxAverages, x0.getTime());
            if (dataIdx < 0 || undefined === minMaxAverages[dataIdx]) {
              return;
            }
            focus.select(".tooltip-row-" + (axis + 1)).text(minMaxAverages[dataIdx][4] + ' - ' + minMaxAverages[dataIdx][5] + ' %');
            focus.select(".tooltip-date").text(timeFormatterAlt(minMaxAverages[dataIdx][0]));

            dots[axis]
              .attr('cx', x(x0.getTime()) - x(x0))
              .attr('cy', yAxes[axis](minMaxAverages[dataIdx][5]) - yAxes[axis](yy[axis]));
          }
          else {
            focus.select(".tooltip-row-" + (axis + 1)).text(data[dataIdx].humidity + ' %');
            dots[axis]
              .attr('cx', x(x0.getTime()) - x(x0))
              .attr('cy', yAxes[axis](data[dataIdx].humidity) - yAxes[axis](yy[axis]));
          }
        }
        if (type === ChartType.battery) {
          focus.select(".tooltip-row-" + (axis + 1)).text(data[dataIdx].battery + ' v');
          dots[axis]
            .attr('cx', x(x0.getTime()) - x(x0))
            .attr('cy', yAxes[axis](data[dataIdx].battery) - yAxes[axis](yy[axis]));
        }
        if (type === ChartType.rssi) {
          focus.select(".tooltip-row-" + (axis + 1)).text(data[dataIdx].rssi + 'dBm');
          dots[axis]
            .attr('cx', x(x0.getTime()) - x(x0))
            .attr('cy', yAxes[axis](data[dataIdx].rssi) - yAxes[axis](yy[axis]));
        }
        if (type === ChartType.uv) {
          focus.select(".tooltip-row-" + (axis + 1)).text(data[dataIdx].uv);
          dots[axis]
            .attr('cx', x(x0.getTime()) - x(x0))
            .attr('cy', yAxes[axis](data[dataIdx].uv) - yAxes[axis](yy[axis]));
        }
      });
    }

    const updateChart = () => {
      x = d3.event.transform.rescaleX(originalX);

      const newDomain = x.domain();
      this.loadMoreData.next([this.state.selectedDevice, newDomain[0].getTime(), newDomain[newDomain.length - 1].getTime()]);
      const xAxis = svg.select('.axis-bottom');
      xAxis.call(d3.axisBottom(x))

      this.state.chartType.forEach((c, i) => {
        // Not sure if want to zoom on y axis
        // const newY = d3.event.transform.rescaleY(originalYAxes[i]);
        const newY = yAxes[i];
        yAxes[i] = newY;
        if (i === 0) {
          const yAxis = svg.select('.axis-left');
          yAxis.call(d3.axisLeft(yAxes[i]))
        }
        else {
          const yAxis = svg.select('.axis-right');
          yAxis.call(d3.axisRight(yAxes[i]))
        }

        switch(c) {
          case ChartType.temp:
            if (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year) {
              const tempArea = d3.area<[Date, number, number, number, number, number]>()
                .x((d,_i) => x(d[0]))
                .y0((d) => yAxes[i](d[1]))
                .y1((d) => yAxes[i](d[2]))
                .curve(d3.curveNatural);

              svg
                .select(".chart-temperature")
                .attr('d', tempArea(getMinMaxAverages()));
            }
            else {
              svg
              .select(".chart-temperature")
              .attr("d", d3.line<SensorHistoryEvent>()
                .x(function(d) { return x(d.date) })
                .y(function(d) { return yAxes[i](d.temperature) })
              );
            }
            break;
          case ChartType.humidity:
            if (this.state.chartDuration === ChartDuration.month || this.state.chartDuration === ChartDuration.year) {
              const humArea = d3.area<[Date, number, number, number, number, number]>()
                .x((d,_i) => x(d[0]))
                .y0((d) => yAxes[i](d[4]))
                .y1((d) => yAxes[i](d[5]))
                .curve(d3.curveNatural);

              svg
                .select(".chart-humidity")
                .attr('d', humArea(getMinMaxAverages()));
            }
            else {
              svg
                .select(".chart-humidity")
                .attr("d", d3.line<SensorHistoryEvent>()
                  .x(function(d) { return x(d.date) })
                  .y(function(d) { return yAxes[i](d.humidity) })
                );
            }
            break;
          case ChartType.battery:
            svg
              .select(".chart-battery")
              .attr("d", d3.line<SensorHistoryEvent>()
                .x(function(d) { return x(d.date) })
                .y(function(d) { return yAxes[i](d.battery) })
              );
            break;
          case ChartType.rssi:
            svg
              .select(".chart-rssi")
              .attr("d", d3.line<SensorHistoryEvent>()
                .x(function(d) { return x(d.date) })
                .y(function(d) { return yAxes[i](d.rssi) })
              );
            break;
          case ChartType.uv:
            svg
              .select(".chart-uv")
              .attr("d", d3.line<SensorHistoryEvent>()
                .x(function(d) { return x(d.date) })
                .y(function(d) { return yAxes[i](d.uv) })
              );
            break;
        }
      });
    }

    const zoom = d3.zoom()
      .scaleExtent([.5, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
      .extent([[0, 0], [containerWidth, screenHeight]])
      .on("zoom", updateChart);

    svg.append("rect")
      .attr("class", "overlay")
      .attr("width", containerWidth - 100)
      .attr("height", screenHeight - 50)
      .style("pointer-events", "all")
      .on("mouseover", function() { focus.style("display", null); })
      .on("mouseout", function() { focus.style("display", "none"); })
      .on("mousemove", mousemove)
      .call(zoom);
}

  handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      chartDuration: ChartDuration[ChartDuration[(event.target as HTMLInputElement).value as any] as any] as any
    }, () => {
      const d = this.state.devices.find(x => x.sensor_id === this.state.selectedDevice);
      if (d) {
        this.fetchHistory(d);
      }
    });
  }

  handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const typeToAdd = ChartType[ChartType[(event.target as HTMLInputElement).value as any] as any] as unknown as ChartType;
    let newTypes;
    if (this.state.chartType.indexOf(typeToAdd) >= 0) {
      console.log('removing');
      newTypes = this.state.chartType.filter(x => x !== typeToAdd);
    }
    else {
      newTypes = [typeToAdd].concat(this.state.chartType);
    }
    if (newTypes.length === 0) {
      newTypes.push(ChartType.temp);
    }

    if (newTypes.length > 2) {
      newTypes.pop();
    }
    this.setState({
      chartType: newTypes
    }, () => {
      this.renderChart(this.lastFetchedData);
    });
  }

  render() {
    const devices = this.state.devices.map(device => {
      return (<div key={device.sensor_id} className={'device ' + (this.state.selectedDevice && this.state.selectedDevice === device.sensor_id ? 'selected' : '')} onClick={() => this.selectDevice(device)}>
        <RouterIcon />
        <span>{device.name ? device.name : 'Sensor: ' + device.sensor_id}</span>
      </div>);
    });
    let selectedDeviceChart: JSX.Element | null = null;
    if (this.state.selectedDevice) {
      const device = this.state.devices.find(x => x.sensor_id === this.state.selectedDevice);
      if (!device) {
        return
      }

      const chartOptions = (<FormControl component="div" className="chart-control">
        <RadioGroup aria-label="position" name="position" value={this.state.chartDuration} onChange={this.handleDurationChange} row>
          <FormControlLabel
            value={ChartDuration.day}
            control={<Radio color="primary" />}
            label="Päev"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartDuration.week}
            control={<Radio color="primary" />}
            label="Nädal"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartDuration.month}
            control={<Radio color="primary" />}
            label="Kuu"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartDuration.year}
            control={<Radio color="primary" />}
            label="Aasta"
            labelPlacement="top"
          />
        </RadioGroup>
        <div>
          <FormControlLabel
            value={ChartType.temp}
            checked={this.state.chartType.indexOf(ChartType.temp) >= 0}
            onChange={this.handleTypeChange}
            control={<Checkbox style ={{color: `rgb(${TypeColor[ChartType.temp]})`}} />}
            label="Temperatuur"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartType.humidity}
            checked={this.state.chartType.indexOf(ChartType.humidity) >= 0}
            onChange={this.handleTypeChange}
            control={<Checkbox style ={{color: `rgb(${TypeColor[ChartType.humidity]})`}} />}
            label="Õhuniiskus"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartType.battery}
            checked={this.state.chartType.indexOf(ChartType.battery) >= 0}
            onChange={this.handleTypeChange}
            control={<Checkbox style ={{color: `rgb(${TypeColor[ChartType.battery]})`}} />}
            label="Aku"
            labelPlacement="top"
          />
          <FormControlLabel
            value={ChartType.rssi}
            checked={this.state.chartType.indexOf(ChartType.rssi) >= 0}
            onChange={this.handleTypeChange}
            control={<Checkbox style ={{color: `rgb(${TypeColor[ChartType.rssi]})`}} />}
            label="Raadio"
            labelPlacement="top"
          />
          {device.sensors?.includes('Tocon') ?
            <FormControlLabel
              value={ChartType.uv}
              checked={this.state.chartType.indexOf(ChartType.uv) >= 0}
              onChange={this.handleTypeChange}
              control={<Checkbox style ={{color: `rgb(${TypeColor[ChartType.uv]})`}} />}
              label="UV"
              labelPlacement="top"
            />
            : null
          }
        </div>
      </FormControl>
      )

      selectedDeviceChart = (<div className="device-chart">
        <div>
          {chartOptions}
        </div>
        <svg ref={this.state.chartRef} />
      </div>)
    }

    return (<Container className="history-root">
      <Grid container className="history-devices">
        {devices}
      </Grid>
      {selectedDeviceChart}
    </Container>);
  }
}
