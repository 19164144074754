import React, { useEffect, useState } from "react";
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import Sensors, { SensorEvent } from './sensors/Sensors';
import History from './sensors/History';
import Configuration from './configuration/Configuration';
import Debug from './debug/Debug';
import './App.scss';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import BugReportIcon from '@material-ui/icons/BugReport';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import RefreshIcon from '@material-ui/icons/Refresh';
import TimelineIcon from '@material-ui/icons/Timeline';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Subject, fromEvent, merge, switchMap, timer } from 'rxjs';
import ReconnectingWebSocket from 'reconnecting-websocket';
import deviceManager from './deviceManager';
import { Temp } from "./Tmp";

interface AndroidWebInterface {
  reload: () => void;
};

const events = [
  'keydown',
  'mousemove',
  'touchend',
  'scroll',
  'DOMContentLoaded'
];

const eventStreams = events.map((ev) => fromEvent(document, ev));
const allEvents$ = merge(...eventStreams);

const handleRelaod = () => {
  const androidWebInterface = (window as any).Android as AndroidWebInterface;
  if (androidWebInterface !== undefined) {
    androidWebInterface.reload();
  }
  else {
    window.location.reload()
  }
};

const resetAtMidnight = () => {
  const now = new Date();
  const night = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      3, 0, 0
  );
  const msToMidnight = night.getTime() - now.getTime();

  setTimeout(() => {
      handleRelaod();
  }, msToMidnight);
}
resetAtMidnight();

const AppRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [homeButtonVisible, setHomeButtonVisible] = useState(location.pathname !== '/');
  const open = Boolean(anchorEl);

  // Init device manager
  useEffect(() => {
    if (loaded) {
      return;
    }
    deviceManager.init()
    .then(() => {
      setLoaded(true);
    })
  }, [loaded]);

  useEffect(() => {
    const subs = allEvents$
    .pipe(
      switchMap(() => timer(60 * 1000))
    )
    .subscribe(() => {
      if (location.pathname !== '/') {
        navigate('/');
      }
    });

    return () => {
      subs.unsubscribe();
    }
  }, [location.pathname, navigate]);

  if (!loaded) {
    return <h1>Loading...</h1>;
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHomeButtonVisible(true);
  };

  if (window.location.host === 'saun.kzfv.eu') {
    return <Temp />
  }
  let homePageButton: JSX.Element | null = homeButtonVisible ? (
    <IconButton onClick={() => setHomeButtonVisible(false)} className="white auto-hide" component={Link} to="/">
      <ShowChartIcon />
    </IconButton>) : <div />;

  return (
    <div className="content">
      <div className="nav">
        {homePageButton}
        <IconButton
          className="auto-hide"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem component={Link} to="/configuration" onClick={handleClose}>
            <SettingsIcon /> Seaded
          </MenuItem>
          <MenuItem component={Link} to="/history" onClick={handleClose}>
            <TimelineIcon /> Ajalugu
          </MenuItem>
          <MenuItem onClick={handleRelaod}>
            <RefreshIcon /> Lae uuesti
          </MenuItem>
          <MenuItem component={Link} to="/debug" onClick={handleClose}>
            <BugReportIcon /> Debug
          </MenuItem>
        </Menu>
      </div>
      <div className='routes'>
        <Routes>
          <Route path="/" element={<Sensors />} />
          <Route path="/history" element={<History />} />
          <Route path="/configuration/" element={<Configuration />} />
          <Route path="/debug/" element={<Debug />} />
        </Routes>
      </div>
    </div>
  );
}

const server = window.location.hostname;
const port = parseInt(window.location.port);
const secure = window.location.protocol === 'https:';

let API;
let ws;
const serverWsPath = process.env.REACT_APP_WS_PATH || 'ws';
if (!isNaN(port) && port !== 443 && port !== 80) {
  API = '//' + server + ':' + port + '/api';
  ws = 'ws://' + server + ':' + port + '/'+ serverWsPath;
}
else {
  API = '//' + server + '/api';
  if (secure) {
    ws = 'wss://' + server + '/'+ serverWsPath;
  }
  else {
    ws = 'ws://' + server + '/'+ serverWsPath;
  }
}

const sensorEventSubject = new Subject<SensorEvent>();
const sunEventSubject = new Subject<number>();

const rws = new ReconnectingWebSocket(ws);

rws.addEventListener('open', () => {
  console.info('Opened websocket connection');
});

rws.addEventListener('message', e => {
  if (e.data?.startsWith('!')) {
    handleSystemMessage(e.data);
  }
  else {
    const msg = JSON.parse(e.data) as SensorEvent;
    console.log('From sensor', msg);
    sensorEventSubject.next(msg);
  }
});
rws.addEventListener('error', e => {
  console.error(e);
});
rws.addEventListener('close', e => {
  console.log('Websocket connection closed', e);
});

setInterval(() => {
  rws.send('!ping');
}, 5000);

const handleSystemMessage = (msg: string) => {
  switch (msg) {
    case '!pong':
      break;
  }
}

export default AppRouter;
export { sensorEventSubject, sunEventSubject, API };
