import React from 'react';
import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.filter.js';

export default class Moon extends React.Component<{progress: number}, {}> {
  el: HTMLDivElement;

  render() {
    return <div className='moon-wrap' ref={(el) => { this.el = el }}></div>
  }

  componentDidMount() {
    const targetSize = Math.max(200, Math.min(400, window.innerWidth * 0.2)) * 2;
    this.el.style.width = targetSize + 'px';
    this.el.style.height = targetSize + 'px';
    var draw = SVG().addTo(this.el).size(targetSize, targetSize);
    draw.rect().attr({width:"100%", height:"100%", fill: '#000' })
    draw.image('/moon_med.png').attr({x:"5%", y:"5%", width:"90%", height:"90%"});
    var blur = (targetSize / 90);
    const phase = this.props.progress;
    const size = targetSize;
    let new_rx;
    if (phase <= 0.25){
      new_rx = .5*Math.cos(2*Math.PI*phase) * 100;
      var shadow1 = draw.rect().attr({width:"50%", height:"100%", fill: '#000'});
      var shadow2 = draw.ellipse().attr({cx:"50%", cy:"50%", rx: (new_rx +"%"), ry:"46.5%"});
      var group = draw.group().attr({fill:"#000", opacity:".8", style:"mix-blend-mode: multiply;"});
      group.add(shadow1).add(shadow2).filterWith(function(add){add.gaussianBlur(blur, blur)});
    }
    else if (phase < 0.5){
      new_rx = -.25*Math.cos(2*Math.PI*phase);
      draw.path('M 0 0 h ' + size/2 + ' v ' + size* 5/100 + ' a ' + size*9*new_rx/5 + ' ' + size*9/20 + ' 0 1 0 0 ' + size*9/10 + ' v ' + size*5/100 + ' h -' + size/2 + ' z')
        .attr({style:"fill:solid; opacity: .8"})
        .filterWith(function(add){add.gaussianBlur(blur, blur)});
    }
    else if (phase < 0.75){
      new_rx = -.25*Math.cos(2*Math.PI*phase);
      draw.path('M ' + size + ' 0 h -' + size/2 + ' v ' + size* 5/100 + ' a ' + size*9*new_rx/5 + ' ' + size*9/20 + ' 0 1 1 0 ' + size*9/10 + ' v ' + size*5/100 + ' h ' + size/2 + ' z')
        .attr({style:"fill:solid;opacity: .8"})
        .filterWith(function(add){add.gaussianBlur(blur, blur)});
    }
    else {
      new_rx = .5*Math.cos(2*Math.PI*phase) * 100;
      var shadow1 = draw.rect().attr({x:"50%", width:"50%", height:"100%"});
      var shadow2 = draw.ellipse().attr({cx:"50%", cy:"50%", rx: (new_rx+"%"), ry:"46.5%"});
      var group = draw.group().attr({fill:"#000", opacity:"0.8", style:"mix-blend-mode: multiply;"});
      group.add(shadow1).add(shadow2).filter(function(add){add.gaussianBlur(blur, blur)});
    }
  }
}
