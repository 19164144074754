import { API } from './App';
import { Device } from './configuration/Configuration';


class DeviceManager {

  devices: Device[];
  loaded = false;

  constructor() {
    this.devices = [];
  }

  public init(): Promise<void> {
    return this.loadDevices();
  }

  private loadDevices(): Promise<void> {
    return fetch(API + '/devices')
    .then(response => response.json())
    .then((devices: Device[]) => {
      this.devices = devices;
      this.loaded = true;
    });
  }

  public getDevice(sensorId: string): Device | undefined {
    return this.devices.find(x => x.sensor_id === sensorId);
  }

  public getAllDevices(): Device[] {
    return this.devices;
  }

  public getAllActiveDevices(): Device[] {
    return this.devices.filter(x => x.status === 'active');
  }

  public updateDevice(device: Device) {
    const idx = this.devices.findIndex(x => x.id === device.id);
    if (idx < 0) {
      this.devices.push(device);
    }
    else {
      this.devices[idx] = device;
    }
  }
}

export default new DeviceManager();
