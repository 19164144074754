import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

const setvh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setvh();
window.addEventListener('resize', setvh);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const userAgent = navigator.userAgent.toLowerCase();
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
if (isTablet) {
  let timeout;
  const hideStaticButtons = () => {
    document.querySelectorAll('.auto-hide').forEach(button => {
      button.classList.add('hide-button');
    });
  }

  const showStaticButtons = () => {
    document.querySelectorAll('.auto-hide').forEach(button => {
      button.classList.remove('hide-button');
    });
  }

  const resetTimeout = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(hideStaticButtons, 30000);
  }

  window.addEventListener('click', () => {
    showStaticButtons();
    resetTimeout();
  });
  resetTimeout();
}

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('black')) {
  document.body.classList.add('black');
}
