import { Slider, withStyles, Button } from '@material-ui/core';
import React from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { saveDeviceProperty } from '../configuration/Configuration';
import DeviceManager from '../deviceManager';

export interface NotificationData {
  temp: number;
  shouldSound: boolean;
};

const getNotificationForSensor = (sensorId: string): NotificationData => {
  const device = DeviceManager.getAllDevices().find(x => x.sensor_id === sensorId);
  if (undefined === device) {
    return;
  }
  const now = new Date().getTime();
  const postponedUntil = device.meta.postponed_until || 0;
  if (undefined !== device.meta.notify_on_temp) {
    return {
      temp: device.meta.notify_on_temp,
      shouldSound: now >= postponedUntil,
    };
  }
}

const removeNotification = (sensorId: string) =>  {
  saveDeviceProperty(sensorId, 'meta', JSON.stringify({})).subscribe();
}

const postponeNotification = (sensorId: string) => {
  const device = DeviceManager.getDevice(sensorId);
  if (device) {
    const meta = device.meta || {};
    meta.postponed_until = (new Date().getTime() + 1000 * 60 * 60 *6);
    console.log(meta);
    saveDeviceProperty(sensorId, 'meta', JSON.stringify(meta)).subscribe();
  }
}

export { getNotificationForSensor, removeNotification, postponeNotification }

const PrettoSlider = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0)',
    width: '10 !important',
    height: 300
  },
  thumb: {
    height: 5,
    width: 50,
    borderRadius: 0,
    color: '#ddd'
  },
  track: {
    height: 8,
    borderRadius: 4,
    width: '10px !important',
  },
  rail: {
    width: '40px !important',
    background: 'linear-gradient(0deg, rgba(255,239,0,1) 0%, rgba(255,0,0,1) 100%)',
    opacity: .8
  },
})(Slider);

type Props = {sensor_id: string, current_temp: number};
type State = {temp: number, isNotificationSet: boolean};

class SaunaModal extends React.Component<Props, State> {

  saveNotificationSubject = new Subject<number>();
  subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);

    this.state = {
      temp: 40,
      isNotificationSet: false
    };
  }

  componentDidMount() {
    const savedNotification = getNotificationForSensor(this.props.sensor_id);
    let savedTemp = 40;
    if (undefined !== savedNotification) {
      savedTemp = savedNotification.temp;
    }
    this.setState({
      temp: savedTemp,
      isNotificationSet: undefined !== savedNotification
    });

    this.subscriptions.push(this.saveNotificationSubject.pipe(debounceTime(500)).subscribe(valueToSave => {
      this.setNotification(this.props.sensor_id, valueToSave);
    }))
  }

  componentWillUnmount() {
    this.saveNotificationSubject.complete();
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  handleChange = (_event, value) => {
    this.setState({temp: value});
    if (this.state.isNotificationSet) {
      this.saveNotificationSubject.next(value);
    }
  }

  setNotification = (sensor_id, temp) => {
    console.log('setNotification');
    this.setState({isNotificationSet: true})
    saveDeviceProperty(sensor_id, 'meta', JSON.stringify({notify_on_temp: temp})).subscribe();
  }

  render() {
    const button = this.state.isNotificationSet ?
    <Button onClick={() => {
      removeNotification(this.props.sensor_id)
      this.setState({isNotificationSet: false, temp: 40});
    } } variant="contained" color="secondary">Kustuta teavitus</Button>
    :
    <Button onClick={() => this.setNotification(this.props.sensor_id, this.state.temp)} variant="outlined" color="secondary">Määra teavitus</Button>

    return (
      <>
      <div className="sauna-model">
        <div className="left-column">
          <h2>Siht: <span className="big-number">{this.state.temp}℃</span></h2>
          <h3>Praegu: {this.props.current_temp}℃</h3>
          {button}
        </div>
        <PrettoSlider
          className="sauna-slider"
          orientation="vertical"
          step={1}
          min={20}
          max={100}
          value={this.state.temp}
          onChange={this.handleChange} />
        </div>
      </>
    );
  }


}

export default SaunaModal
