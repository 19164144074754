import React, { useEffect, useState } from 'react';
import { API } from './App';


export const Temp = () => {
    const [temp, setTemp] = useState('...');
    useEffect(() => {
        getDataFromServer();
        const int = setInterval(() => {
            getDataFromServer();
        }, 1000 * 60 * 30);

        return () => {
            clearInterval(int);
        }
    }, [])


    const getDataFromServer = () => {
        fetch(API + '/latest')
        .then(function(response) {
            return response.json();
        })
        .then(a => {
            const e = a.filter(x => x.sensor_id === '89');
            if (e.length === 1) {
                setTemp(e[0].temperature);
            }
            
        })
    }
    


    return <div className='be-good'><h1>{temp}</h1></div>

}
