import React from 'react';
import { Subject, Subscription, Observable, throwError } from 'rxjs';
import { Container, List, ListItem, ListItemIcon, ListItemText, Paper, Switch, Grid, Typography, Slider, Button } from '@material-ui/core';
import RouterIcon from '@material-ui/icons/Router';
import CastIcon from '@material-ui/icons/Cast';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import WifiIcon from '@material-ui/icons/Wifi';
import EditIcon from '@material-ui/icons/Edit';
import AutosizeInput from 'react-input-autosize';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import et from 'dayjs/locale/et'
import { fromFetch } from 'rxjs/fetch';

import './Configuration.scss';
import { API, sensorEventSubject } from '../App';
import { sortSensorsByState } from '../sensors/helper';
import { NotInterested } from '@material-ui/icons';
import deviceManager from '../deviceManager';
import { Sensor } from '../sensors/Sensors';
import { Link } from 'react-router-dom';

const marks = [
  {
    value: 4,
    label: '4sek',
  },
  {
    value: 300,
    label: '5min',
  },
  {
    value: 600,
    label: '10min',
  },
  {
    value: 900,
    label: '15min',
  },
  {
    value: 1800,
    label: '30min',
  },
];

type DeviceConfiguration = {
  device_id: number;
  key: number;
  value: number;
  state: string;
}

type DeviceConfigurationFormField = {
  key: number;
  value: number | string;
}

dayjs.extend(relativeTime);
dayjs.locale(et);

export const postRequest = <T,>(url: string, body: any): Observable<T> => {
  return fromFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
  .pipe(
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw throwError(() => new Error(response.statusText));
      }
    }),
    catchError(err => {
      throw throwError(() => new Error(err.message));
    })
  );
}

export const saveDeviceProperty = (sensor_id: string, property: string, value: string): Observable<Device> => {
  if (!(undefined !== property && undefined !== value && value.length > 0 && property.length > 0)) {
    throw throwError(() => new Error('Invalid parameters'));
  }

  return postRequest<Device>(API + '/set_device_property/' + sensor_id, {property, value})
    .pipe(tap(device => {
      deviceManager.updateDevice(device);
    }));
}

export interface Device {
  id: number;
  battery: string;
  name: string;
  status: 'active' | 'inactive';
  rssi: number;
  sensor_id: string;
  updated_at: number;
  sensors?: Sensor[];
  meta?: any;
}

class Configuration extends React.Component<any, {devices: Device[], config: DeviceConfiguration[], selectedDevice: number, pendingForm: DeviceConfigurationFormField[]}> {

  private inputNameRef: any = undefined as any;
  private inputChangeSubject = new Subject<{sensor_id: string, form: DeviceConfigurationFormField[]}>();
  private subscriptions: Subscription[] = []

  constructor(props: any) {
    super(props);
    this.state = {
      devices: [],
      config: [],
      selectedDevice: -1,
      pendingForm: []
    }
    this.inputChangeSubject.pipe(debounceTime(500)).subscribe(event => {
      const device = this.state.devices.find(x => x.sensor_id === event.sensor_id);
      this.saveConfiguration(device, event.form);
    });
  }

  componentDidMount() {
    this.getDevices();
    this.subscriptions.push(sensorEventSubject.subscribe(event => {
      const device = this.state.devices.find(x => x.sensor_id === event.sensor_id);
      if (device !== undefined) {
        device.rssi = event.rssi;
        device.updated_at = event.created;

        this.setState({});
      }
    }));
  }

  componentWillUnmount() {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  selectDevice = (idx: number) => {
    const selectedDevice = this.state.devices[idx];
    const deviceIntervalConfig = this.state.config.find(x => x.device_id === selectedDevice.id && x.key === 3);
    const deviceBlinkConfig = this.state.config.find(x => x.device_id === selectedDevice.id && x.key === 1);
    if (!(selectedDevice && selectedDevice.name && selectedDevice.name.length > 0)) {
      selectedDevice.name = 'Sensor: ' + selectedDevice.sensor_id;
    }

    this.setState({
      selectedDevice: idx,
      pendingForm: [
        {key: 1, value: deviceBlinkConfig ? deviceBlinkConfig.value : 1},
        {key: 3, value: deviceIntervalConfig ? deviceIntervalConfig.value : 8},
        {key: 101, value: selectedDevice.name},
        {key: 102, value: selectedDevice.status},
      ]
    });
  }

  saveConfiguration = (device: Device, configurations: DeviceConfigurationFormField[]) => {
    if (configurations.length > 0) {
      configurations.forEach(x => {
        // Name
        if (x.key === 101) {
          if (device.name !== x.value) {
            this.saveProperty(device.sensor_id, 'name', x.value as string)
          }
        }
        else if (x.key === 102) {
          if (device.status !== x.value) {
            this.saveProperty(device.sensor_id, 'status', x.value as string)
          }
        }
        else {
          const current = this.state.config.find(y => y.key === x.key && y.device_id === device.id);
          if (current && current.value === x.value) {
            // Value was not changed
            return;
          }
          return fetch(API + '/set/' + device.sensor_id + '/' + x.key + '/' + x.value, {method: 'POST'})
            .then(response => response.json())
            .then(_res => {
              if (current) {
                current.value = x.value as number;
              }
            });
        }
      })
    }
  }

  saveProperty(sensorId: string, key: string, value: string) {
    saveDeviceProperty(sensorId, key, value)
    .subscribe((res: Device) => {
      const stateDevice = this.state.devices.find(x => x.id === res.id);
      Object.assign(stateDevice, res);
      this.setState({});
    });
  }

  getDevices() {
    fetch(API + '/config')
    .then((response) => {
      return response.json();
    })
    .then(config => {
      this.setState({
        devices: sortSensorsByState(deviceManager.getAllDevices()),
        config: config
      })
    });
  }

  getFormField(key: number) {
    return this.state.pendingForm.find(x => x.key === key) || {value: 0};
  }

  render() {
    const devices = this.state.devices.map((device, idx) => {
      let icon = <CastIcon />;
      if (device.sensor_id === '100') {
        icon = <RouterIcon />
      }
      if (device.status === 'inactive') {
        icon = <NotInterested />
      }
      return  (
        <ListItem
          key={device.id}
          button
          selected={this.state.selectedDevice === idx}
          className={device.status === 'inactive' ? 'inactive' : 'active'}
          onClick={() => this.selectDevice(idx)}
        >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={device.name ? device.name : 'Sensor: ' + device.sensor_id} />
        </ListItem>
      );
    });


    let device: JSX.Element | null = null;
    if (this.state.selectedDevice >= 0) {
      const selectedDevice = this.state.devices[this.state.selectedDevice];

      let interval = this.getFormField(3).value;
      let blink = this.getFormField(1).value === 1 ? true : false;
      let name = this.getFormField(101).value;
      let active = this.getFormField(102).value === 'active' ? true : false;

      const handleChange = (name: string) => (event: React.ChangeEvent<any>, value?: any) => {
        if (name === 'blink') {
          const field = this.getFormField(1);
          if (field) {
            field.value = event.target.checked ? 1 : 0;
          }
        }
        if (name === 'interval') {
          const field =  this.getFormField(3);
          if (field) {
            field.value = value
          }
        }
        if (name === 'name') {
          const field =  this.getFormField(101);
          if (field) {
            field.value = event.target.value;
          }
        }
        if (name === 'active') {
          const field = this.getFormField(102);
          if (field) {
            field.value = event.target.checked ? 'active' : 'inactive';
          }
        }
        this.inputChangeSubject.next({sensor_id: selectedDevice.sensor_id, form: this.state.pendingForm});
        this.setState({});
      };

      // FIXME this is no last contact time
      const lastContactTime = dayjs.unix(selectedDevice.updated_at);
      device = (<Paper className="device">
        <form onSubmit={(e) => {
          e.preventDefault();
          if (this.inputNameRef) {
            this.inputNameRef.blur();
          }
          // Turn off autosave for now
          this.inputChangeSubject.next({sensor_id: selectedDevice.sensor_id, form: this.state.pendingForm});
        }}>
          <Typography variant="h5" className="flex">
            <AutosizeInput
              id="name-inpue"
              className="name-input"
              placeholder="Nimi"
              placeholderIsMinWidth
              value={name}
              ref={(ref: any) => { this.inputNameRef = ref; }}
              onChange={handleChange('name')}
            />
            <EditIcon
              className="edit-name-input"
              onClick={() => this.inputNameRef && this.inputNameRef.focus()}
            />
          </Typography>
        </form>
        <Grid className="device-info-container" container spacing={1}>
          {selectedDevice.sensor_id !== '100' ? (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} className="label">
              <Typography>Interval</Typography>
            </Grid>
            <Grid item xs={8} className="grid-slider">
              <Slider
                step={10}
                min={4}
                max={1800}
                marks={marks}
                valueLabelDisplay="on"
                value={interval as number}
                onChange={handleChange('interval')}
              />
            </Grid>
          </Grid>) : (null)}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Typography>ID</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{selectedDevice.sensor_id}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Typography>Viimane ühendus</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography><span className="time">{lastContactTime.format('HH:mm:ss')}</span> - {lastContactTime.format('YYYY-MMM-DD')} - {dayjs().to(lastContactTime)}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} className="label">
              <Typography>Vilguta LEDi</Typography>
            </Grid>
            <Grid item xs={8} className="grid-switch">
              <Switch
                checked={blink}
                onChange={handleChange('blink')}/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} className="label">
              <Typography>Aktiivne</Typography>
            </Grid>
            <Grid item xs={8} className="grid-switch">
              <Switch
                checked={active}
                onChange={handleChange('active')}/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <BatteryFullIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography>{selectedDevice.battery ? selectedDevice.battery : '- '}v</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <WifiIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography>{selectedDevice.rssi ? selectedDevice.rssi : '- '}dBm</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Typography>Sensorid</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{selectedDevice.sensors?.join(', ')}</Typography>
            </Grid>
          </Grid>
          {/*}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={() => this.saveConfiguration(selectedDevice, this.state.pendingForm)}>
                Salvesta
              </Button>
            </Grid>
          </Grid>
        {*/}
        </Grid>
      </Paper>)
    }

    return (<Container className="conf-root" fixed>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <List component="div">
            {devices}
          </List>
          <Link to={'/'}>
            <Button variant="contained" color="primary" className="back-button">
              Tagasi
            </Button>
          </Link>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            {device}
          </Paper>
        </Grid>
      </Grid>
    </Container>);
  }
}

export default Configuration;
