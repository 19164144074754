import React, { createRef } from 'react';
import dayjs from 'dayjs';
import et from 'dayjs/locale/et'
import { API, sunEventSubject } from '../App';
import * as d3 from 'd3';

import './Sky.scss';
import { Subscription } from 'rxjs';
import Moon from './Moon';

dayjs.locale(et);

type SkyData = {
  sun: {
    sunrise: number;
    sunset: number;
    sun_pos: number;
  },
  moon: {
    age_percent: number;
    age_seconds: number;
    current_phase: number;
    cycle: number[],
    illumination: number;
  }
}

export default class Sky extends React.Component<any, {data?: SkyData}> {
  moonSVGRef: React.RefObject<SVGSVGElement>;
  sunSVGRef: React.RefObject<SVGSVGElement>;
  intervalId;
  subscriptions: Subscription[] = [];

  constructor(props: any) {
    super(props)
    this.moonSVGRef = createRef<SVGSVGElement>();
    this.sunSVGRef = createRef<SVGSVGElement>();
    this.state = {};
  }

  componentDidMount() {
    this.fetchSkyInformation();
    this.intervalId = setInterval(this.fetchSkyInformation, 1000 * 60);
    this.subscriptions.push(sunEventSubject.subscribe((pos) => {
      if (this.state.data) {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.data.sun.sun_pos = pos;
        this.drawSunGraph();
      }
    }));
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  fetchSkyInformation = () => {
    fetch(API + '/sky')
    .then(response => response.json())
    .then(data => {
      this.setState({
        data
      }, () => {
        this.drawSunGraph();
      });
    })
    .catch(err => {
      console.log(err);
    })
  }

  drawSunGraph() {
    if (!this.sunSVGRef.current) {
      return;
    }
    d3.select(this.sunSVGRef.current).selectAll('*').remove();
    const width = 250;
    const height = width * 2;
    const angleInRadians = this.state.data.sun.sun_pos * (Math.PI / 180);
    const sunAltitude = Math.sin(angleInRadians) * (width - 90);
    const svg = d3.select(this.sunSVGRef.current)
      .attr('width', width)
      .attr('height', height)

    svg.append('line')
      .attr('x1', width - 100)
      .attr('y1', height / 2)
      .attr('x2', width)
      .attr('y2', height / 2)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '2 5')
      .attr('stroke', '#ccc');

    svg.append('image')
      .attr('xlink:href', '/sun.svg')
      .attr('x', width - 100)
      .attr('y', (height / 2 -50) - sunAltitude)
      .attr('width', 100)
      .attr('height', 100)

    svg.append('image')
      .attr('xlink:href', '/sunrise.svg')
      .attr('x', 0)
      .attr('y', ((height / 2 - 25)) - 25)
      .attr('width', 50)
      .attr('height', 50)

    svg.append('image')
      .attr('xlink:href', '/sunset.svg')
      .attr('x', 0)
      .attr('y', ((height / 2 - 25) + 25))
      .attr('width', 50)
      .attr('height', 50)

    svg.append('image')
      .attr('xlink:href', '/length.svg')
      .attr('x', 0)
      .attr('y', ((height - 50)))
      .attr('width', 50)
      .attr('height', 30)

    const sunriseTime = dayjs.unix(this.state.data.sun.sunrise);
    const sunsetTime = dayjs.unix(this.state.data.sun.sunset);
    const day = 24 * 60 * 60;
    let diff = day - (this.state.data.sun.sunrise - this.state.data.sun.sunset);
    if (diff > day) {
      diff -= day;
    }

    const secondsToHms = (d: number) => {
      d = Number(d);
      const h = Math.floor(d / 3600);
      const m = Math.floor(d % 3600 / 60);
      // var s = Math.floor(d % 3600 % 60);

      const hDisplay = h > 0 ? h + ' tundi ' : "";
      const mDisplay = m > 0 ? m + ' minutit ' : "";
      // var sDisplay = s > 0 ? s + ' sekundit ' : "";
      return hDisplay + mDisplay;
    }

    const sunrise = sunriseTime.format('HH:mm');
    const sunset = sunsetTime.format('HH:mm');
    const dayLength = secondsToHms(diff);

    svg.append('image')
      .attr('xlink:href', '/height.svg')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 50)
      .attr('height', 30)
    svg.append('text')
      .attr('x', 60)
      .attr('y', 15)
      .attr('fill', '#ccc')
      .attr('font-size', 20)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Kõrgus ∠ ' + this.state.data.sun.sun_pos.toFixed(2) + '°');

    svg.append('text')
      .attr('x', 60)
      .attr('y', height - 50 + 15)
      .attr('fill', '#ccc')
      .attr('font-size', 20)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(dayLength);

    svg.append('text')
      .attr('x', 60)
      .attr('y', ((height / 2) - 25))
      .attr('fill', '#ccc')
      .attr('font-size', 20)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(sunrise);

    svg.append('text')
      .attr('x', 60)
      .attr('y', ((height / 2) + 25))
      .attr('fill', '#ccc')
      .attr('font-size', 20)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(sunset);

  }

  render() {
    if (!this.state.data) {
      return <div />;
    }
    let moonElement: JSX.Element;
    if (this.state.data?.moon.age_percent >= 0) {
      const moonPhases = ['Kuuloomine', 'Poolkuu', 'Täiskuu', 'Vanakuu', 'Kuuloomine']
      moonElement = <div className='moon'>
        <div className='moon-phases'>
          {
            this.state.data.moon.cycle.map((x, i) => {
              const label = dayjs.unix(x).format('YYYY-MM-DD');
              const isActivePhase = this.state.data.moon.current_phase === i
              return (
                <div key={'moon-' + x} className={'moon-phase' + (isActivePhase ? ' active' : '')}>
                  <span className='name'>{moonPhases[i]}</span>
                  <span>{label}</span>
                </div>)
            })
          }
        </div>

        <Moon progress={this.state.data.moon.age_percent / 100} />
      </div>
    }

    return (<div className='sky-container'><div className="sky">
      <div className="sun">
        <div className="center">
          <svg ref={this.sunSVGRef}></svg>
        </div>
      </div>
      {
        moonElement
      }
    </div></div>);
  }

}
